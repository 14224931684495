<template>
  <!-- 面试详情==》》个人信息页面 -->
  <div class="viewInfo">
    <van-nav-bar :title="$t('confirmInterviewInfo.confirmInfoTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <div class="content">
      <!-- 基本信息 -->
      <div class="baseInfo">
        <!-- 面试人基本信息 -->
        <div class="infoCard">
          <div class="zp">
            <svg-icon style="width: 100%; height: 100%"
                      icon-class="default_avator" />
          </div>
          <div class="up">
            <p>{{ baseInfo.empname }}</p>
            <p>
              <span>{{ baseInfo.workyear
                }}{{ $t("confirmInterviewInfo.confirmInfoTxt2") }}</span>
              <span>{{ baseInfo.degree }}</span>
              <span>{{ baseInfo.age
                }}{{ $t("confirmInterviewInfo.confirmInfoTxt3") }}</span>
            </p>
            <p>{{ baseInfo.reqjob }}</p>
          </div>
          <div class="down">
            <span>{{ baseInfo.jobstatus }}</span>
          </div>
        </div>
        <!-- 招聘人信息 -->
        <div class="recruitInfo">
          <!-- 简历附件 -->
          <van-collapse v-model="fileActiveNames">
            <!-- :title="$t('confirmInterviewInfo.confirmInfoTxt30')" -->
            <van-collapse-item :title="'简历附件'"
                               v-if="$t('confirmInterviewInfo.confirmInfoTxt30')"
                               :name="1">
              <ul v-if="fileList.length > 0"
                  style="
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                ">
                <li style="text-align: center; padding: 0px 20px 10px 20px"
                    v-for="(item, index) in fileList"
                    :key="index"
                    @click="showFile(item)">
                  <img v-if="item.type == 'doc'"
                       src="@/assets/img/word.png"
                       alt />
                  <img v-else-if="item.type == 'xls'"
                       src="@/assets/img/excel2.png"
                       alt />
                  <img v-else-if="item.type == 'ppt'"
                       src="@/assets/img/ppt2.png"
                       alt />
                  <img v-else-if="item.type == 'pic'"
                       src="@/assets/img/picture.png"
                       alt />
                  <img v-else-if="item.type == 'zip'"
                       src="@/assets/img/zip.png"
                       alt />
                  <img v-else-if="item.type == 'default' || 'pdf'"
                       src="@/assets/img/xmind.png"
                       alt />
                  <br />
                  {{ item.sfilename }}
                </li>
              </ul>
              <ul v-else>
                <li style="text-align: justify">
                  {{ $t("confirmInterviewInfo.confirmInfoTxt31") }}
                </li>
              </ul>
            </van-collapse-item>
          </van-collapse>
          <!-- 主表信息 -->
          <van-collapse v-model="newActiveNames">
            <van-collapse-item v-for="(item, idx) in groupInfo"
                               :key="idx"
                               :title="item[0].appgroupname"
                               :name="idx">
              <van-row v-for="(it, key) in item"
                       :key="key"
                       v-show="it.formshow">
                <van-col span="8">
                  <span>{{ it.chnname }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.value }}</span>
                </van-col>
              </van-row>
            </van-collapse-item>
          </van-collapse>

          <!-- 子表信息 -->
          <van-collapse v-model="dtTableActiveNames">
            <van-collapse-item v-for="(item, idx) in dtTableGroupInfo"
                               :key="idx"
                               :title="item.groupName"
                               :name="idx">
              <div v-for="(it, key) in item.groupList"
                   :key="key">
                <p style="background: #e7e7e7">
                  {{ item.groupName + (key + 1) }}
                </p>
                <van-row v-for="(el, index) in it"
                         :key="index"
                         v-show="el.formshow">
                  <van-col span="8">
                    <span>{{ el.chnname }}</span>
                  </van-col>
                  <van-col span="15"
                           offset="1">
                    <span>{{ el.value }}</span>
                  </van-col>
                </van-row>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
      <!-- 个人优势 -->
      <van-collapse v-model="activeNames">
        <van-collapse-item :title="$t('confirmInterviewInfo.confirmInfoTxt17')"
                           v-if="$t('confirmInterviewInfo.confirmInfoTxt17')"
                           name="3">
          <ul>
            <li style="text-align: justify">{{ baseInfo.selfdesp }}</li>
          </ul>
        </van-collapse-item>
        <van-collapse-item :title="$t('confirmInterviewInfo.confirmInfoTxt18')"
                           v-if="$t('confirmInterviewInfo.confirmInfoTxt18') && show_in_app_resumelist"
                           name="4">
          <div class="baseInfo"
               style="
              padding-left: 0;
              padding-right: 0;
              padding-top: 0;
              padding-bottom: 0;
              border-bottom: 1px solid #ccc;
            "
               v-for="(value, key) in RsIV"
               :key="key">
            <div class="recruitInfo">
              <van-row>
                <van-col span="8">
                  <span>{{ $t("confirmInterviewInfo.confirmInfoTxt19") }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span style="color: #2b8df0">第{{ value.iv_times
                    }}{{ $t("confirmInterviewInfo.confirmInfoTxt20") }}</span>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="8">
                  <span>{{ $t("confirmInterviewInfo.confirmInfoTxt21") }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.iv_date }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.iv_res">
                <van-col span="8">
                  <span>{{ $t("confirmInterviewInfo.confirmInfoTxt22") }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.iv_res }}</span>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="8">
                  <span>{{ $t("confirmInterviewInfo.confirmInfoTxt23") }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.empname }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.f_empcode1">
                <van-col span="8">
                  <span>{{ $t("confirmInterviewInfo.confirmInfoTxt24") }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.f_empcode1 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.f_empcode2">
                <van-col span="8">
                  <span>{{ $t("confirmInterviewInfo.confirmInfoTxt25") }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.f_empcode2 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.f_empcode3">
                <van-col span="8">
                  <span>{{ $t("confirmInterviewInfo.confirmInfoTxt26") }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.f_empcode3 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.f_empcode4">
                <van-col span="8">
                  <span>{{ $t("confirmInterviewInfo.confirmInfoTxt27") }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.f_empcode4 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.f_empcode5">
                <van-col span="8">
                  <span>{{ $t("confirmInterviewInfo.confirmInfoTxt28") }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.f_empcode5 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="value.iv_pj">
                <van-col span="8">
                  <span>{{ $t("confirmInterviewInfo.confirmInfoTxt29") }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ value.iv_pj }}</span>
                </van-col>
              </van-row>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <!-- 底部 -->
    <div class="viewFooter">
      <span @click="signView">{{
        $t("confirmInterviewInfo.confirmInfoTxt32")
      }}</span>
      <span @click="zitoElimination">{{
        $t("confirmInterviewInfo.confirmInfoTxt33")
      }}</span>
    </div>
    <!-- pdf内置播放器 -->
    <pdfView :pdf="pdf"></pdfView>
    <!-- 同意面试弹窗 -->
    <van-dialog v-model="showDia"
                :title="$t('confirmInterviewInfo.confirmInfoTxt32')"
                show-cancel-button
                :confirm-button-text="$t('module.confirm')"
                :cancel-button-text="$t('module.cancel')"
                :before-close="closeDia">
      <div style="padding: 10px">
        <van-field readonly
                   clickable
                   clearable
                   name="datetimePicker"
                   :value="iv_time"
                   :label="$t('confirmInterview.confirmTxt15')"
                   :placeholder="$t('module.SelectDate')"
                   @click="showIvDatePicker(1)" />
        <van-field readonly
                   clickable
                   name="datetimePicker"
                   :value="iv_bt1"
                   :label="$t('confirmInterview.confirmTxt18')+1"
                   :placeholder="$t('module.SelectDateTime')"
                   @click="showIvTimePicker('iv_bt1')" />
        <van-field readonly
                   clickable
                   name="datetimePicker"
                   :value="iv_et1"
                   :label="$t('confirmInterview.confirmTxt19')+1"
                   :placeholder="$t('module.SelectDateTime')"
                   @click="showIvTimePicker('iv_et1')"
                   style="margin-bottom: 10px;" />
        <van-field v-if="$t('confirmInterview.confirmTxt16')"
                   readonly
                   clickable
                   name="datetimePicker"
                   :value="iv_time2"
                   :label="$t('confirmInterview.confirmTxt16')"
                   :placeholder="$t('module.SelectDate')"
                   @click="showIvDatePicker(2)" />
        <van-field v-if="$t('confirmInterview.confirmTxt16')"
                   readonly
                   clickable
                   name="datetimePicker"
                   :value="iv_bt2"
                   :label="$t('confirmInterview.confirmTxt18')+2"
                   :placeholder="$t('module.SelectDateTime')"
                   @click="showIvTimePicker('iv_bt2')" />
        <van-field v-if="$t('confirmInterview.confirmTxt16')"
                   readonly
                   clickable
                   name="datetimePicker"
                   :value="iv_et2"
                   :label="$t('confirmInterview.confirmTxt19')+2"
                   :placeholder="$t('module.SelectDateTime')"
                   @click="showIvTimePicker('iv_et2')"
                   style="margin-bottom: 10px;" />
        <van-field v-if="$t('confirmInterview.confirmTxt17')"
                   readonly
                   clickable
                   name="datetimePicker"
                   :value="iv_time3"
                   :label="$t('confirmInterview.confirmTxt17')"
                   :placeholder="$t('module.SelectDate')"
                   @click="showIvDatePicker(3)" />
        <van-field v-if="$t('confirmInterview.confirmTxt17')"
                   readonly
                   clickable
                   name="datetimePicker"
                   :value="iv_bt3"
                   :label="$t('confirmInterview.confirmTxt18')+3"
                   :placeholder="$t('module.SelectDateTime')"
                   @click="showIvTimePicker('iv_bt3')" />
        <van-field v-if="$t('confirmInterview.confirmTxt17')"
                   readonly
                   clickable
                   name="datetimePicker"
                   :value="iv_et3"
                   :label="$t('confirmInterview.confirmTxt19')+3"
                   :placeholder="$t('module.SelectDateTime')"
                   @click="showIvTimePicker('iv_et3')" />
      </div>
    </van-dialog>
    <!-- 不同意面试弹窗 -->
    <van-dialog v-model="showBTYDia"
                :title="$t('confirmInterviewInfo.confirmInfoTxt33')"
                show-cancel-button
                :confirm-button-text="$t('module.confirm')"
                :cancel-button-text="$t('module.cancel')"
                :before-close="closeBTYDia">
      <div style="padding: 10px">
        <van-cell class="desc_cell"
                  required
                  :value="$t('confirmInterview.confirmTxt11')" />
        <van-field class="desc_field"
                   v-model="reason"
                   rows="3"
                   autosize
                   readonly
                   type="textarea"
                   :placeholder="$t('confirmInterview.confirmTxt11')"
                   @click="getReasonPicker" />
      </div>
    </van-dialog>
    <!-- 面试日期弹窗 -->
    <van-popup v-model="showPicker"
               round
               position="bottom">
      <van-datetime-picker type="date"
                           :min-date="minDate"
                           :max-date="maxDate"
                           :formatter="formatter"
                           @confirm="onConfirm"
                           @cancel="showPicker = false" />
    </van-popup>
    <!-- 面试时间弹窗 -->
    <van-popup v-model="showTimePic"
               round
               position="bottom">
      <van-datetime-picker type="time"
                           @confirm="onConfirmByTime"
                           @cancel="showTimePic = false" />
    </van-popup>

    <!-- 不同意面试原因弹窗 -->
    <van-popup v-model="showReasonPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="Reasoncolumns"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showReasonPicker = false"
                  @confirm="onConfirmReason" />
    </van-popup>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { Notify } from "vant";
import pdfView from "@components/pdfView/index.vue";
import { getResumeDetail, getOtherParamInfo } from "@api/wxzp.js";
import {
  getInfo as getbaseforminfo,
  GetFilterDropDownList,
  GetFormFieldDetail,
  GetFormRecordData,
  queryGridData,
} from "@api/wx.js";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import {
  checkIv,
  unCheckIv,
  getModuleAttFileList,
  checkFileExists,
  getDownLoadFileName,
} from "@api/wxzp.js";
import { Dialog, Toast } from "vant";
export default {
  components: {
    pdfView,
  },
  data () {
    return {
      userInfo,
      footerType: "",
      show: false,
      baseInfo: {},
      RsWorkHis: [],
      activeNames: [],
      RsEduHis: [],
      url: "",
      RsIV: [],
      fileList: [], // 附件列表
      attachfilepath: "AttachFile",
      pdf: "",
      showDia: false,
      showPicker: false,
      minDate: new Date(),
      maxDate: new Date(2121, 11, 31),
      iv_time: "",
      showBTYDia: false,
      reason: "", // 不同意原因
      showReasonPicker: false,
      Reasoncolumns: [],
      moduleBaseInfo: {}, // 模块配置信息
      groupInfo: [], // 主表分组信息
      newActiveNames: [], // 主表分组
      dttableinfo: [], // 子表配置信息集合
      dtTableGroupInfo: [], // 子表分组集合
      dtTableActiveNames: [], // 子表分组
      fileActiveNames: [1], // 简历附件
      iv_time2: null,
      iv_time3: null,
      chooseTimer: 1,
      iv_bt1: '',
      iv_et1: '',
      iv_bt2: '',
      iv_et2: '',
      iv_bt3: '',
      iv_et3: '',
      whichTime: '',
      showTimePic: false,
      show_in_app_resumelist: true
    }
  },
  watch: {
    // 查询子表信息
    dttableinfo (n) {
      let realDtTables = n.filter((el) => {
        return el.show_in_app_resumelist == true;
      });
      if (realDtTables.length > 0)      {
        this.dtTableGroupInfo = [];
        realDtTables.forEach((it) => {
          let obj = {
            groupName: it.detailtbname,
            show_inform_style: it.show_inform_style,
            groupList: [],
          };
          let data = {};
          getbaseforminfo({
            moduleno: it.moduleno,
            userid: 1,
            langid: 0,
            flag: 0,
            mtag: 0,
          }).then((res) => {
            data = res.moduleconfiginfo[0];
            const querysql =
              it.dtkeyfield + " = '" + this.$route.query.autoid + "'";
            const params = {
              moduleno: it.moduleno,
              userid: this.userInfo.userid,
              username: this.userInfo.username,
              hasempidfield: data.hasempidfield,
              mviewname: data.mviewname,
              fieldlist: data.fieldlist,
              orderbysql: data.orderbysql,
              pagequery: false,
              pagesize: 15,
              pageindex: 1,
              moduleqyfilter: data.moduleqyfilter,
              specfilter: data.specfilter,
              querysql: querysql,
              fastquerylist: "",
            };
            queryGridData(params).then((res) => {
              // let groupList = []
              res.data.forEach((e) => {
                // 请求构造表单的接口
                GetFormFieldDetail({
                  moduleno: it.moduleno,
                  userid: this.userInfo.userid,
                  langid: 0,
                  checkfieldpri: data.checkfieldpri || false,
                  colcount: data.colcount,
                  labelwidth: data.labelwidth,
                  controlwidth: data.controlwidth,
                  mviewname: data.mviewname,
                  mkeyname: data.mkeyname,
                  formfieldlist: data.formfieldlist,
                  autoid: 0,
                }).then((res) => {
                  let list = res.fieldlist;
                  // 获取字段值
                  GetFormRecordData({
                    mviewname: data.mviewname,
                    mkeyname: data.mkeyname,
                    formfieldlist: data.formfieldlist,
                    autoid: e.autoid,
                  }).then((res) => {
                    let recordData = res.data;
                    let keys = Object.keys(recordData[0]);
                    recordData.forEach((el) => {
                      list.forEach((item) => {
                        item.value = "";
                        item.chnname = item.chnname.replace("<br>", "");
                        if (keys.includes(item.fieldname))                        {
                          item.value = el[item.fieldname];
                        }
                      });
                    });
                    obj.groupList.push(list);
                  });
                });
              });
            });
          });

          this.dtTableGroupInfo.push(obj);
          // 子表附表根据主表中的dttableinfo.show_inform_style 控制，show_inform_style=2表示表单显示
          if (obj.show_inform_style == 2)          {
            this.dtTableActiveNames.push(this.dtTableGroupInfo.length - 1);
          }
          // console.log(this.dtTableGroupInfo, 'this.dtTableGroupInfo');
        });
      }
    },
  },
  created () {
    this.getResumeDetailData();
    this.getModuleAttFileListData();
    this.getBaseForm();
  },
  methods: {
    // 获取其他参数接口
    _getOtherParamInfo () {
      getOtherParamInfo().then((res) => {
        if (res.data.length > 0)        {
          if (res.data[0].app_expand_all_group)          {
            //移动端简历信息默认展开所有分组
            for (let index = 0; index < this.groupInfo.length; index++)            {
              this.newActiveNames.push(index);
            }
          }
        }
      });
    },
    // 获取主表配置信息
    getBaseForm () {
      getbaseforminfo({
        moduleno: 304,
        userid: 1,
        langid: 0,
        flag: 0,
        mtag: 0,
      }).then((res) => {
        let moduleConfig = res.moduleconfiginfo[0];
        // 获取子表接口信息
        this.dttableinfo = res.dttableinfo;
        for (let i = 0; i < this.dttableinfo.length; i++){
          if (this.dttableinfo[i].moduleno == 304011){
            this.show_in_app_resumelist = this.dttableinfo[i].show_in_app_resumelist;
            break;
          }
        }
        // 请求构造表单的接口
        GetFormFieldDetail({
          moduleno: 304,
          userid: this.userInfo.userid,
          langid: 0,
          checkfieldpri: moduleConfig.checkfieldpri || false,
          colcount: moduleConfig.colcount,
          labelwidth: moduleConfig.labelwidth,
          controlwidth: moduleConfig.controlwidth,
          mviewname: moduleConfig.mviewname,
          mkeyname: moduleConfig.mkeyname,
          formfieldlist: moduleConfig.formfieldlist,
          autoid: this.$route.query.autoid,
        }).then((res) => {
          let list = res.fieldlist;
          // 获取字段值
          GetFormRecordData({
            mviewname: moduleConfig.mviewname,
            mkeyname: moduleConfig.mkeyname,
            formfieldlist: moduleConfig.formfieldlist,
            autoid: this.$route.query.autoid,
          }).then((res) => {
            let recordData = res.data;
            let totalarr = [];
            const indexarr = [];
            let map = {},
              dest = []; // 用来分组的工具
            let keys = Object.keys(recordData[0]);
            recordData.forEach((el) => {
              list.forEach((item) => {
                item.value = "";
                item.chnname = item.chnname.replace("<br>", "");
                if (keys.includes(item.fieldname))                {
                  item.value = el[item.fieldname];
                }
                item.uptools = true; //默认打开所有分组
                item.oldvalue = ""; //记录旧值
                item.haschange = false;
                // if (
                //   (item.fieldname === 'telephone' &&
                //     this.mode === 'true' &&
                //     this.moduleno === '240') ||
                //   (((item.fieldname === 'telephone' && this.mode === 'true') ||
                //     item.fieldname === 'staname' ||
                //     item.fieldname === 'stationid') &&
                //     this.seStaList.includes(this.moduleno))
                // )
                // {
                //   item.isreadonly = true
                // } else if (
                //   item.fieldname === 'telephone' &&
                //   this.mode === 'true' &&
                //   this.moduleno == '322'
                // )
                // {
                //   item.isreadonly = true
                // }
                if (item.controltype === "memo")                {
                  item.showline = true;
                  item.linecaption = item.chnname;
                  // item.appgroupname = item.chnname
                }
                if (item.formshow && item.showline)                {
                  // 分组线名称
                  const idx = list.indexOf(item);
                  indexarr.push(idx);
                }
                // if (item.formshow && item.controltype === 'memo')
                // {
                //   textboxnum++
                // }
                // console.log(this.masterisactive, '1111')
                // let new_masterisactive = !this.masterisactive
                //   ? '1'
                //   : this.masterisactive.length === 0
                //     ? '1'
                //     : this.masterisactive.substr(0, 1)

                // if (new_masterisactive.substr(0, 1) === '5' && !item.isreadonly)
                // {
                //   item.isreadonly = item.atreadonly
                // }

                //-------------------分组
                if (item.formshow)                {
                  item.formdata = "";
                }
                const param = "appgroupname";
                if (item.formshow && item[param])                {
                  // if (item.controltype == 'memo')
                  // {
                  //   dest.push({ name: item.chnname, data: [item] });
                  // } else
                  // {
                  if (!map[item[param]])                  {
                    dest.push({ name: item[param], data: [item] });
                    map[item[param]] = item;
                  } else                  {
                    for (var j = 0; j < dest.length; j++)                    {
                      var dj = dest[j];
                      if (dj.name == item[param])                      {
                        dj.data.push(item);
                        break;
                      }
                    }
                  }
                  // }
                }
              });
            });

            // 代表有分组
            if (dest.length > 0)            {
              dest.forEach((el, idx) => {
                if (idx == dest.length - 1)                {
                  list.forEach((it) => {
                    if (!it.appgroupname)                    {
                      el.data.push(it);
                    }
                  });
                }
                totalarr.push(el.data);
              });
            } else            {
              // 没有分组信息 就全部放入一个里
              let noGroup = [];
              list.forEach((it) => {
                noGroup.push(it);
              });
              totalarr.push(noGroup);
            }
            // console.log(totalarr, 'totalarrtotalarrtotalarr');
            this.groupInfo = totalarr;
            // 获取 app_expand_all_group 是否展开主表字段
            this._getOtherParamInfo();
          });
        });
      });
    },
    // 打开不同意面试原因弹窗
    getReasonPicker () {
      GetFilterDropDownList({
        moduleno: 201,
        username: this.userInfo.username,
        listsql: "@安排面试不同意原因",
      }).then((res) => {
        if (res.data.length > 0)        {
          for (let i = 0; i < res.data.length; i++)          {
            this.Reasoncolumns.push(res.data[i].dicvalue);
          }
        }
        // console.log(res)
        this.showReasonPicker = true;
      });
    },
    // 不同意面试原因弹窗回调
    onConfirmReason (value) {
      this.reason = value;
      this.showReasonPicker = false;
      this.Reasoncolumns = [];
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? "0" + m : m;
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)      {
        const time = new Date(date);
        const y = time.getFullYear();
        const m = time.getMonth() + 1;
        const d = time.getDate();
        const h = time.getHours();
        const mm = time.getMinutes();
        const s = time.getSeconds();
        if (rule == "yyyy-MM-dd")        {
          return y + "-" + this.add0(m) + "-" + this.add0(d);
        } else        {
          return (
            y +
            "-" +
            this.add0(m) +
            "-" +
            this.add0(d) +
            " " +
            this.add0(h) +
            ":" +
            this.add0(mm)
          );
          // + ':' + this.add0(s)
        }
      } else      {
        return "";
      }
    },
    formatter (type, val) {
      if (type === "year")      {
        return val + "年";
      }
      if (type === "month")      {
        return val + "月";
      }
      if (type === "day")      {
        return val + "日";
      }
      return val;
    },
    // 面试签到
    signView () {
      this.iv_time = "";
      this.iv_bt1 = ''
      this.iv_et1 = ''
      this.iv_time2 = "";
      this.iv_bt2 = ''
      this.iv_et2 = ''
      this.iv_time3 = "";
      this.iv_bt3 = ''
      this.iv_et3 = ''
      this.showDia = true
    },
    // 打开面试日期pop
    showIvDatePicker (val) {
      this.chooseTimer = val
      this.showPicker = true
    },
    // 打开面试时间pop
    showIvTimePicker (val) {
      this.whichTime = val
      this.showTimePic = true
    },
    // 日期选择关闭回调
    onConfirm (time) {
      if (this.chooseTimer == 1)      {
        this.iv_time = this.formatDate(time, 'yyyy-MM-dd');
      } else if (this.chooseTimer == 2)      {
        this.iv_time2 = this.formatDate(time, 'yyyy-MM-dd');
      } else if (this.chooseTimer == 3)      {
        this.iv_time3 = this.formatDate(time, 'yyyy-MM-dd');
      }
      this.showPicker = false;
    },
    // 时间选择回调
    onConfirmByTime (time) {
      if (this.whichTime == 'iv_bt1')      {
        this.iv_bt1 = time
      } else if (this.whichTime == 'iv_et1')      {
        this.iv_et1 = time
      } else if (this.whichTime == 'iv_bt2')      {
        this.iv_bt2 = time
      } else if (this.whichTime == 'iv_et2')      {
        this.iv_et2 = time
      } else if (this.whichTime == 'iv_bt3')      {
        this.iv_bt3 = time
      } else if (this.whichTime == 'iv_et3')      {
        this.iv_et3 = time
      }
      this.showTimePic = false;
    },
    // 弹窗关闭回调
    closeDia (action, done) {
      if (action == "confirm")      {
        // if (this.iv_time)
        // {
        checkIv({
          autoid: this.$route.query.autoid,
          iv_datetime: this.iv_time,
          iv_datetime2: this.iv_time2,
          iv_datetime3: this.iv_time3,
          iv_bt1: this.iv_bt1,
          iv_et1: this.iv_et1,
          iv_bt2: this.iv_bt2,
          iv_et2: this.iv_et2,
          iv_bt3: this.iv_bt3,
          iv_et3: this.iv_et3,
          username: this.userInfo.username
        }).then(res => {
          Toast(this.$t('module.editSuc'))
          done()
          this.$router.push('/confirmInterview')
        })
        // } else
        // {
        //   Toast.fail(this.$t('confirmInterview.confirmTxt13'))
        //   done(false)
        // }
      } else      {
        done();
      }
    },
    onClickLeft () {
      this.$router.push("/confirmInterview");
    },
    zitoElimination () {
      this.reason = "";
      this.showBTYDia = true;
      // Dialog.confirm({
      //   title: this.$t('confirmInterviewInfo.confirmInfoTxt34'),
      //   message: this.$t('confirmInterviewInfo.confirmInfoTxt36'),
      // })
      //   .then(() => {
      //     unCheckIv({
      //       autoid: this.$route.query.autoid,
      //       username: this.userInfo.username
      //     }).then(res => {
      //       Toast(this.$t('module.editSuc'));
      //       this.$router.push("/confirmInterview");
      //     })
      //   }).catch(() => {

      //   });
    },
    // 不同意弹窗回调
    closeBTYDia (action, done) {
      if (action == "confirm")      {
        if (this.reason)        {
          unCheckIv({
            autoid: this.$route.query.autoid,
            reason: this.reason,
            username: this.userInfo.username,
          }).then((res) => {
            done();
            Toast(this.$t("confirmInterview.confirmTxt8"));
            this.getdate();
          });
        } else        {
          Toast.fail(this.$t("confirmInterview.confirmTxt12"));
          done(false);
        }
      } else      {
        done();
      }
    },
    onClickRight () { },
    closeEwm () {
      this.show = false;
    },
    //跳转到面试淘汰页面
    toElimination () {
      this.$router.push({
        path: "/viewElimination",
        query: { title: "淘汰" },
      });
    },
    // 跳转到面试通知
    arrangeView () {
      this.$router.push("/interviewNotice");
    },
    // 跳转到Offer通知
    sendOffer () {
      this.$router.push("/offerNotice");
    },
    getResumeDetailData () {
      getResumeDetail({
        autoid: this.$route.query.autoid,
        username: this.userInfo.username,
      }).then((res) => {
        console.log(res, "getResumeDetailData");
        // 基本信息
        this.baseInfo = res.ResumeInfo[0];
        // 工作经验
        this.RsWorkHis = res.RsWorkHis;
        // 教育经验
        this.RsEduHis = res.RsEduHis;
        // 面试记录
        this.RsIV = res.RsIV;
      });
    },
    // 获取附件列表
    getModuleAttFileListData () {
      getModuleAttFileList({
        moduleno: 304,
        mautoid: this.$route.query.autoid,
        username: this.userInfo.username,
      }).then((res) => {
        this.fileList = this.filehandleData(res);
        // for (let index = 0; index < this.fileList.length; index++)
        // {
        //   const item = this.fileList[index]
        // }
      });
    },
    // 附件数据处理
    filehandleData (list) {
      let groups = []; // 分组[后缀]
      let res = []; // 第一次分类的集合
      if (list.module_atlist)      {
        list.module_atlist.forEach((item) => {
          let type = "";
          let groupName = !item.aftype ? "" : item.aftype;
          const ext = item.fileext.slice(1); // 后缀
          let url = "";
          if ("doc|docx".includes(ext))          {
            name = "WORD文件";
            type = "doc";
            url = require("@assets/img/word.png");
          } else if ("xlsx|xls".includes(ext))          {
            name = "EXCEL文件";
            type = "xls";
            url = require("@assets/img/excel2.png");
          } else if ("ppt|pptx".includes(ext))          {
            name = "PPT文件";
            type = "ppt";
            url = require("@assets/img/ppt2.png");
          } else if ("pdf".includes(ext))          {
            name = "PDF文件";
            type = "pdf";
            url = require("@assets/img/picture.png");
          } else if ("png|jpg|jpeg|emp".includes(ext))          {
            name = "图片文件";
            type = "pic";
            url = require("@assets/img/picture.png");
          } else if ("zip|rar".includes(ext))          {
            name = "压缩文件";
            type = "zip";
            url = require("@assets/img/zip.png");
          } else          {
            name = "其他文件";
            type = "default";
            url = require("@assets/img/xmind.png");
          }
          item.type = type;
          // item.url = url
          item.isImage = true;
          res.push(item);
        });
      }
      if (list.other_atlist)      {
        list.other_atlist.forEach((item) => {
          let type = "";
          const ext = item.fileext.slice(1); // 后缀
          let groupName = !item.aftype ? "" : item.aftype;
          let url = "";
          if ("doc|docx".includes(ext))          {
            name = "WORD文件";
            type = "doc";
            url = require("@assets/img/word.png");
          } else if ("xlsx|xls".includes(ext))          {
            name = "EXCEL文件";
            type = "xls";
            url = require("@assets/img/excel2.png");
          } else if ("ppt|pptx".includes(ext))          {
            name = "PPT文件";
            type = "ppt";
            url = require("@assets/img/ppt2.png");
          } else if ("pdf".includes(ext))          {
            name = "PDF文件";
            type = "pdf";
            url = require("@assets/img/picture.png");
          } else if ("png|jpg|jpeg|emp".includes(ext))          {
            name = "图片文件";
            type = "pic";
            url = require("@assets/img/picture.png");
          } else if ("zip|rar".includes(ext))          {
            name = "压缩文件";
            type = "zip";
            url = require("@assets/img/zip.png");
          } else          {
            name = "其他文件";
            type = "default";
            url = require("@assets/img/xmind.png");
          }
          item.type = type;
          // item.url = url
          item.isImage = true;
          res.push(item);
        });
      }
      return res;
    },
    // 点击附件
    showFile (item) {
      if (item.type === "pic" || "pdf")      {
        const params = {
          moduleno: item.moduleno,
          dflag: 2,
          // autoid: parseInt(this.$route.query.autoid),
          autoid: item.mautoid,
          filename: item.sfilename.split(".")[0], // filename不要带后缀
          fileext: item.fileext,
          downloadpath: this.attachfilepath,
        };
        checkFileExists(params).then((re) => {
          if (re == "OK")          {
            getDownLoadFileName(params).then((e) => {
              // this.fileList[index].url = e
              if (item.type === "pic")              {
                ImagePreview([e]);
              } else if (item.type === "pdf")              {
                this.pdf = e + "?time=" + new Date().getTime();
              } else              {
                var u = navigator.userAgent;
                var isAndroid =
                  u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if (isAndroid)                {
                  //android终端
                  window.open(e, "_blank");
                } else if (isiOS)                {
                  //ios终端
                  window.location.href = e;
                } else                {
                  window.open(e, "_blank");
                }
              }
            });
          } else          {
            Notify({ type: "warning", message: this.$t("module.nofile") });
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.viewInfo {
  position: relative;
  height: 100vh;
  // overflow: hidden;
  .pdfView {
    height: 0;
  }
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 232px);
    overflow: auto;
    box-sizing: border-box;
    .baseInfo {
      box-sizing: border-box;
      padding: 26px 0px;
      background: #fff;
      // margin-bottom: 24px;
      .infoCard {
        position: relative;
        // width: 690px;
        height: 308px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
        opacity: 1;
        border-radius: 16px;
        padding-left: 36px;
        padding-right: 32px;
        // margin-bottom: 42px;
        margin: 0 32px 40px 32px;
        .zp {
          position: absolute;
          width: 132px;
          height: 132px;
          right: 32px;
          top: 52px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .up {
          border-bottom: 1px solid #ccc;
          padding: 42px 140px 26px 0px;
          box-sizing: border-box;
          p {
            margin: 0;
          }
          p:first-child {
            font-size: 52px;
            height: 52px;
            line-height: 52px;
            font-family: Source Han Sans CN;
            color: #000;
            margin-bottom: 16px;
          }
          p:nth-child(2) {
            font-size: 28px;
            line-height: 28px;
            font-family: Source Han Sans CN;
            color: #666;
            margin-bottom: 12px;
            span:first-child,
            span:nth-child(2) {
              border-right: 1px solid #999999;
            }
            span {
              padding: 0 14px;
            }
            span:first-child {
              padding-left: 0;
            }
          }
          p:nth-child(3) {
            line-height: 36px;
            font-size: 30px;
            font-family: Source Han Sans CN;
          }
        }
        .down {
          height: 84px;
          line-height: 84px;
          box-sizing: border-box;
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: #666;
        }
      }
      .recruitInfo {
        p {
          font-size: 36px;
          font-family: Source Han Sans CN;
          color: #000;
        }
        .van-row {
          margin-bottom: 40px;
          .van-col:first-child {
            font-size: 32px;
            font-family: Source Han Sans CN;
            color: #999;
          }
          .van-col:last-child {
            font-size: 32px;
            font-family: Source Han Sans CN;
            color: #333;
          }
        }
      }
    }
    .van-collapse {
      /deep/.van-collapse-item {
        .van-cell {
          .van-cell__title {
            span {
              font-size: 36px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #000000;
              opacity: 1;
            }
          }
        }
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            .education {
              .ed_header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #666;
                font-size: 32px;
                font-family: Source Han Sans CN;
                margin-bottom: 18px;
                .ed_hl {
                  display: flex;
                  align-items: center;
                  .circle {
                    display: inline-block;
                    box-sizing: border-box;
                    width: 30px;
                    height: 30px;
                    background: #ffffff;
                    border: 6px solid #2b8df0;
                    border-radius: 50%;
                    margin-right: 16px;
                  }
                  span {
                  }
                }
                .ed_hr {
                }
              }
              .ed_body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 80px;
                border-left: 1px solid #ccc;
                margin-bottom: 18px;
                margin-left: 14px;
                .ed_bl {
                  box-sizing: border-box;
                  font-family: Source Han Sans CN;
                  padding-left: 66px;
                  span:first-child {
                    display: inline-block;
                    width: 60px;
                    height: 36px;
                    text-align: center;
                    line-height: 36px;
                    border: 2px solid #2b8df0;
                    border-radius: 8px;
                    color: #2b8df0;
                    font-size: 24px;
                    margin-right: 16px;
                  }
                  span:last-child {
                    font-size: 32px;
                    color: #333333;
                  }
                }
                .ed_br {
                  font-size: 32px;
                  color: #333333;
                }
              }
            }
            .workHistory {
              .workgs {
                display: flex;
                align-items: center;

                .circle {
                  display: inline-block;
                  box-sizing: border-box;
                  width: 30px;
                  height: 30px;
                  background: #ffffff;
                  border: 6px solid #2b8df0;
                  border-radius: 50%;
                  margin-right: 16px;
                }
                span:last-child {
                  font-size: 32px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #000;
                }
              }
              .work_body {
                padding: 40px 0px;
                padding-left: 30px;
                border-left: 1px solid #ccc;
                margin-left: 14px;
                box-sizing: border-box;
                font-size: 32px;

                font-family: Source Han Sans CN;
                color: #333333;
                .work_head {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 30px;
                }

                span {
                }
                p {
                  margin: 0;
                }
              }
            }
            ul {
              li {
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
              }
            }
          }
        }
      }
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
}
.viewFooter {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  span {
    display: inline-block;
    width: 50%;
    height: 100%;
    line-height: 80px;
    box-sizing: border-box;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2b8df0;
    text-align: center;
  }
  span:first-child {
    flex: 1;
    border-right: 1px solid #ccc;
  }
  span:nth-child(2) {
    flex: 1;
    // border-right:1px solid #ccc;
  }
  .van-dropdown-menu {
    flex: 1;
    /deep/ .van-ellipsis {
      font-size: 36px;
      color: #2b8df0;
    }
    /deep/.van-dropdown-menu__title--down::after {
    }
  }
}
</style>
